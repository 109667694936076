import React from "react";
import { Container, Text, Button, ButtonAlt, Image } from "@atoms";

const Hero = ({ heading, descriptor, button, secondaryLink, image }) => {
  return (
    <section className="relative z-20 flex min-h-1/3-screen flex-col justify-center py-10 sm:py-20">
      <Container className="flex flex-col gap-3 sm:flex-row">
        <div className="order-2 flex w-full flex-shrink-0 flex-col gap-6 sm:order-1 sm:w-[55%] md:gap-10">
          <Text richText className="bold-teal max-w-xl font-bold">
            {heading}
          </Text>
          {descriptor && (
            <Text variant="xl" className="mb-1 max-w-md">
              {descriptor}
            </Text>
          )}
          <div className="flex flex-wrap gap-6">
            {!!button?.url && <Button to={button.url}>{button.text}</Button>}
            {!!secondaryLink?.url && (
              <ButtonAlt to={secondaryLink.url}>{secondaryLink.text}</ButtonAlt>
            )}
          </div>
        </div>
        <div className="relative order-1 w-full pb-[100%] sm:order-2 sm:pb-0">
          <div className="absolute left-0 -mt-[10%] w-[110%] -rotate-[11.5deg] animate-scaleup rounded-full bg-purple-dark pb-[110%] sm:-top-16 sm:mt-0 sm:w-[135%] sm:pb-[135%]">
            <div className="absolute inset-0 flex animate-slideup items-center justify-center">
              {!!image && <Image image={image} />}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
